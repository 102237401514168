import './App.scss';

function App() {
  return (
    <>
      <header>
        Sweep
      </header>

      <main>
        Coming soon.
      </main>
    </>
  );
}

export default App;
